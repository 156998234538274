//Hali edits for Alice on the Go program
//menu button
#block-block-1 {
    .header-info {
      ul {
        li {
          &.alice-login {
            margin: 0;
            font-size: 1em;
            a {
              color: white;
              font-weight: 700;
              font-size: 1em;
              text-transform: uppercase;
              background-color: #443c53;
              padding: 0.5em 1em;
  
              transition: all 0.2s;
              &:hover {
                background-color: white;
                color: #30293d;
              }
              &:after {
                font-family: FontAwesome;
                content: "\f090";
                width: 10px;
                height: 10px;
                margin-left: 10px;
                color: #fea327;
              }
            }
          }
        }
      }
    }
  }
  
  #block-block-2 {
    .header-info {
      ul {
        li {
          &.alice-login {
            margin: 0;
            font-size: 1em;
            margin-bottom: 20px;
            a {
              color: white;
              font-weight: 700;
              font-size: 1em;
              text-transform: uppercase;
              background-color: #443c53;
              padding: 0.5em 1em;
  
              transition: all 0.2s;
              &:hover {
                background-color: white;
                color: #30293d;
              }
              &:after {
                font-family: FontAwesome;
                content: "\f090";
                width: 10px;
                height: 10px;
                margin-left: 10px;
                color: #fea327;
              }
            }
          }
        }
      }
    }
  }
  .page-node-135, .page-node-136, .page-node-140 {
    h1 {
      border-bottom: 2px solid #fea327;
    }
    a {
      color: #443c53;
      text-decoration: underline;
      &:hover {
        color: #c1bbcf;
      }
    }
    .quick-escape-inner {
      a {
        text-decoration: none;
        &:hover {
            color: white;
          }
      }
    }
  }
  
  .alice-wrapper {
    .testimonial-desktop {
      display: none;
      font-size: 1.2rem;
  
      background: #443c53;
      color: #c1bbcf;
      padding: 60px 50px;
      margin-left: 30px;
      -webkit-box-shadow: -9px 9px 0px 0px #c1bbcf;
      box-shadow: -9px 9px 0px 0px #c1bbcf;
      @media only screen and (min-width: 768px) {
        float: right;
        width: 40%;
        display: block;
      }
    }
    .author {
      color: white;
      font-size: 1rem;
    }
    .testimonial-mobile {
      font-size: 1.2rem;
      margin-top: 40px;
      background: #443c53;
      color: #c1bbcf;
      padding: 40px 30px;
      margin-left: 30px;
      -webkit-box-shadow: -9px 9px 0px 0px #c1bbcf;
      box-shadow: -9px 9px 0px 0px #c1bbcf;
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  #block-webform-client-block-134, #block-webform-client-block-141 {
    background-color: #cdc8d8;
    padding: 5%;
    margin-top: 30px;
    color: #332c40;
    @media only screen and (min-width: 768px) {
      padding: 5% 13%;
    }
  
    h2,
    label {
      color: #332c40;
    }
    label {
      margin-bottom: 10px;
    }
    .form-radios,
    .form-checkboxes {
      display: flex;
      .form-type-radio,
      .form-type-checkbox {
        display: flex;
        margin-left: 1rem;
        &:first-child {
          margin-left: 0;
        }
        //width: 10%;
        input {
          width: 30px;
          margin-top: 3px;
        }
      }
    }
    input[type="radio"],
    input[type="checkbox"] {
      border: 0px;
      width: 100%;
      height: 1.25rem;
    }
    .form-required {
      color: #443c53;
    }
  
    .form-text,
    .form-textarea {
      border: 1px solid #30293d;
    }
    .description {
      font-style: italic;
      font-size: 0.9rem;
    }
    .webform-component--confidentiality-agreement {
      .description {
        margin-bottom: 20px;
      }
    }
    .form-item-submitted-confidentiality-agreement-yes {
      label {
        margin-left: 10px;
        font-weight: 600;
      }
    }
    .form-actions {
      border-top: 1px solid #30293d;
      .webform-submit {
        margin-top: 20px;
        background-color: #30293d;
        transition: all 0.2s;
        &:hover {
          background-color: white;
          color: #30293d;
        }
      }
    }
  }
  
  #block-block-11, #block-block-9 {
    .logo-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      max-width: 800px;
      margin: 20px auto;
      @media only screen and (min-width: 768px) {
        flex-direction: row;
      }
      img {
        max-width: 180px;
        max-height: 180px;
        align-self: center;
      }
      p {
        padding: 50px;
      }
    }
  }
  